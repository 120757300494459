import { Card } from 'antd';
import React from 'react';
import DriversList from './components/DriversList';

const Drivers = () => {
  return (
    <Card>
      <div className="d-flex align-center justify-between flex-wrap">
        <h1>Drivers</h1>
        <div id="driver-add-btn" className="d-flex" />
      </div>
      <hr />
      <DriversList />
    </Card>
  );
};

export default Drivers;
