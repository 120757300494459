import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Trucks from './Trucks';

const TruckWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.TRUCKS} component={Trucks} />
    </Switch>
  );
};

export default TruckWrapper;
