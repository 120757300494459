import { gql } from '@apollo/client';

export const CREATE_DRIVER = gql`
  mutation createDriver($data: DriverCreateData!) {
    createDriver(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_DRIVER = gql`
  mutation updateDriver($data: DriverUpdateData!, $where: CommonWhereInput!) {
    updateDriver(data: $data, where: $where) {
      status
      message
    }
  }
`;

export const DELETE_DRIVER = gql`
  mutation deleteDriver($where: CommonWhereInput!) {
    deleteDriver(where: $where) {
      status
      message
    }
  }
`;
