import { Layout } from 'antd';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../common/constants';
import './App.css';
import AppHeader from './components/header/AppHeader';
import MobileHeader from './components/header/MobileHeader';
import ContentRoutes from './ContentRoutes';

const { Content, Footer } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.laptop}px)`);

  return (
    <Layout className="gx-app-layout">
      {!isDesktopViewport && !collapsed && (
        <MobileHeader collapsed={collapsed} setCollapsed={setCollapsed} />
      )}
      <Layout>
        <AppHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content className="gx-layout-content">
          <div className="gx-main-content-wrapper">
            <ContentRoutes />
          </div>
          <Footer>
            <div className="gx-layout-footer-content">
              {' '}
              © {new Date().getFullYear()}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
