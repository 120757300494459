import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { ROUTES } from '../common/constants';
import Error404 from '../Error404';
import DriverWrapper from '../modules/drivers';
import TrailerWrapper from '../modules/trailers';
import TruckWrapper from '../modules/trucks';
import UserWrapper from '../modules/users';

const ContentRoutes = () => {
  const {
    state: { selectedFacilityId }
  } = useContext(AppContext);
  return (
    <>
      {selectedFacilityId && (
        <Switch>
          <Route
            exact
            path={ROUTES.MAIN}
            render={() => <Redirect to={ROUTES.DRIVERS} />}
          />
          <Route path={ROUTES.TRAILERS} component={TrailerWrapper} />
          <Route path={ROUTES.TRUCKS} component={TruckWrapper} />
          <Route path={ROUTES.DRIVERS} component={DriverWrapper} />
          <Route path={ROUTES.USERS_MANAGEMENT} component={UserWrapper} />
          <Route path="*" exact component={Error404} />
        </Switch>
      )}
    </>
  );
};

export default ContentRoutes;
