import { Card } from 'antd';
import React from 'react';
import TrucksList from './components/TrucksList';

const Trucks = () => {
  return (
    <Card>
      <div className="d-flex align-center justify-between flex-wrap">
        <h1>Trucks</h1>
        <div id="truck-add-btn" className="d-flex" />
      </div>
      <hr />
      <TrucksList />
    </Card>
  );
};

export default Trucks;
