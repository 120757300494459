import { Card } from 'antd';
import React from 'react';
import TrailersList from './components/TrailersList';

const Trailers = () => {
  return (
    <Card>
      <div className="d-flex align-center justify-between flex-wrap">
        <h1>Trailers</h1>
        <div id="trailer-add-btn" className="d-flex" />
      </div>
      <hr />
      <TrailersList />
    </Card>
  );
};

export default Trailers;
