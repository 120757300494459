import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_FACILITIES = gql`
  query facilities($skip: Int, $limit: Int, $sortBy: FacilitySortByInput) {
    facilities(skip: $skip, limit: $limit, sortBy: $sortBy) {
      total
      facilities {
        id
        name
        company
      }
    }
  }
`;
