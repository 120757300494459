import { CloseOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useContext } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import logo from '../../../assets/logo.png';
import { BREAKPOINTS } from '../../../common/constants';

const { Sider } = Layout;

const MobileHeader = ({ collapsed, setCollapsed }) => {
  const {
    state: { selectedFacilityId }
  } = useContext(AppContext);
  const isTabletViewport = useMedia(`(min-width: ${BREAKPOINTS.tablet}px)`);

  const items = [
    {
      label: 'Dashboard',
      key: 'dashboard'
    },
    {
      label: 'Tags',
      key: 'tags'
    },
    {
      label: 'BW/MF Orders',
      key: 'bwmf',
      children: [
        {
          label: 'Current Orders',
          key: 'orders'
        },
        {
          label: 'Past Orders',
          key: 'orders/old'
        }
      ]
    },
    {
      label: 'WC Department',
      key: 'wc',
      children: [
        {
          label: 'Current Orders',
          key: 'orders/wc'
        },
        {
          label: 'Past Orders',
          key: 'orders/wc/old'
        }
      ]
    }
  ];

  const handleMenuClick = (e) => {
    // eslint-disable-next-line no-undef
    window.location.replace(
      `${process.env.REACT_APP_OLD_WEB_URL}/${e.key}/?facilityId=${selectedFacilityId}`
    );
  };
  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={null}
      >
        <div className="p-15 sidebar-header d-flex">
          {!isTabletViewport && (
            <div className="logo">
              <img className="logo-content" src={logo} alt="logo" />
            </div>
          )}
          <CloseOutlined
            onClick={() => {
              setCollapsed(true);
            }}
            className="sidebar-svg"
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          items={items}
          className="responsive-menu"
          onClick={handleMenuClick}
        />
      </Sider>
    </>
  );
};

export default MobileHeader;
