import { gql } from '@apollo/client';

export const CREATE_TRAILER = gql`
  mutation createTrailer($data: CreateTrailerInput) {
    createTrailer(data: $data) {
      message
      data {
        id
        name
        isActive
        facility {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_TRAILER = gql`
  mutation updateTrailer(
    $where: CommonWhereInput!
    $data: UpdateTrailerInput!
  ) {
    updateTrailer(where: $where, data: $data) {
      message
      data {
        id
        name
        isActive
        facility {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_TRAILER = gql`
  mutation deleteTrailer($where: CommonWhereInput!) {
    deleteTrailer(where: $where) {
      message
    }
  }
`;
