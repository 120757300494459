import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Drivers from './Drivers';

const DriverWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.DRIVERS} component={Drivers} />
    </Switch>
  );
};

export default DriverWrapper;
