import {
  KeyOutlined,
  LockOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { Driver, Trailer, Truck } from '../../../assets/svg';
import { MODULES, ROUTES } from '../../../common/constants';

const UserProfile = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    state: { selectedFacilityId }
  } = useContext(AppContext);
  const onMenuSelect = (e) => {
    if (
      e.key === ROUTES.USERS ||
      e.key === ROUTES.CHANGE_PASSWORD ||
      e.key === ROUTES.PROFILE
    ) {
      // eslint-disable-next-line no-undef
      window.location.replace(
        `${process.env.REACT_APP_OLD_WEB_URL}${e.key}/?facilityId=${selectedFacilityId}`
      );
    } else {
      history.push(e.key);
    }
  };

  const menu = (
    <Menu
      selectedKeys={[`/${pathname.split('/')[1]}`]}
      defaultSelectedKeys={[ROUTES.MAIN]}
      onClick={onMenuSelect}
      items={[
        {
          type: 'group',
          label: <h3 className="mb-0">Administrator</h3>
        },
        {
          type: 'divider'
        },
        {
          label: MODULES.USERS_MANAGEMENT,
          key: ROUTES.USERS,
          icon: <TeamOutlined />
        },
        {
          label: MODULES.DRIVERS,
          key: ROUTES.DRIVERS,
          icon: <Driver width={15} height={15} fill="#545454" />
        },
        {
          label: MODULES.TRUCKS,
          key: ROUTES.TRUCKS,
          icon: (
            <Truck
              width={15}
              height={15}
              fill="#545454"
              className="truck-svg"
            />
          )
        },
        {
          label: MODULES.TRAILERS,
          key: ROUTES.TRAILERS,
          icon: (
            <Trailer
              width={15}
              height={15}
              fill="#545454"
              className="trailer-svg"
            />
          )
        },
        {
          type: 'group',
          label: <h3 className="mb-0">Account</h3>
        },
        {
          type: 'divider'
        },
        {
          label: MODULES.PROFILE,
          key: ROUTES.PROFILE,
          icon: <UserOutlined />
        },
        {
          label: MODULES.CHANGE_PASSWORD,
          key: ROUTES.CHANGE_PASSWORD,
          icon: <KeyOutlined />
        },
        {
          label: MODULES.LOGOUT,
          key: ROUTES.LOGOUT,
          icon: <LockOutlined />
        }
      ]}
    />
  );

  return (
    <div className="gx-avatar-row">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        arrow
        overlayClassName="user-dropdown"
      >
        <Avatar
          className="gx-size-35 mr-5 pointer"
          alt="Avatar"
          onClick={(e) => e.preventDefault()}
        />
      </Dropdown>
    </div>
  );
};

export default UserProfile;
