import { useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import { trimStart } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { formValidatorRules } from '../../../common/utils';
import { CREATE_TRAILER, UPDATE_TRAILER } from '../graphql/Mutation';

const { required } = formValidatorRules;
const AddEditTrailerModal = ({
  showModal,
  setShowModal,
  refetch,
  isEdit,
  editData,
  setEditData
}) => {
  const [form] = Form.useForm();
  const {
    state: { selectedFacilityId }
  } = useContext(AppContext);

  const [createTrailer, { loading: createLoading }] = useMutation(
    CREATE_TRAILER,
    {
      onError() {}
    }
  );

  const [updateTrailer, { loading: updateLoading }] = useMutation(
    UPDATE_TRAILER,
    {
      onError() {}
    }
  );

  const handleCancel = () => {
    setShowModal(false);
    setEditData();
  };
  const handleOk = () => {
    form.submit();
  };

  const onTrailersFinish = async (formValues) => {
    try {
      if (isEdit) {
        const response = await updateTrailer({
          variables: {
            where: {
              id: editData?.id
            },
            data: {
              name: trimStart(formValues?.name),
              facilityId: selectedFacilityId
            }
          }
        });
        if (response?.data?.updateTrailer) {
          setShowModal(false);
          form.resetFields();
          refetch();
          setEditData();
        }
      } else {
        const response = await createTrailer({
          variables: {
            data: {
              name: trimStart(formValues?.name),
              facilityId: selectedFacilityId
            }
          }
        });
        if (response?.data?.createTrailer) {
          setShowModal(false);
          form.resetFields();
          refetch();
          setEditData();
        }
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      title={`${isEdit ? 'Edit Trailer' : 'Add Trailer'}`}
      okText={`${isEdit ? 'Save' : 'Add'}`}
      onOk={handleOk}
      confirmLoading={createLoading || updateLoading}
      centered
    >
      <Form
        layout="vertical"
        onFinish={onTrailersFinish}
        form={form}
        initialValues={editData}
      >
        <Form.Item
          label="Name"
          rules={[required, { whitespace: true }]}
          name="name"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditTrailerModal;
