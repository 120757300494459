/* eslint-disable no-console */
import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import { TOKEN, USER } from './common/constants';
import { getCookie, getDomain } from './common/utils';

const cookieData = getCookie('auth');

const getLoggedInUser = () => {
  const loggedInUser = cookieData ? JSON.parse(cookieData) : null;
  return loggedInUser?.user;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  // eslint-disable-next-line no-undef
  authToken: cookieData?.accessToken,
  selectedFacilityId: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};
      // eslint-disable-next-line no-undef
      localStorage.setItem(
        USER,
        user && Object.keys(user).length ? JSON.stringify(user) : null
      );
      return { ...state, currentUser: { ...user } };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action.data };

    case 'SET_AUTH': {
      const cookieObj = {
        accessToken: action.data.token,
        refreshToken: action.data.refreshToken,
        roles: action.data.user.roles,
        userId: action.data.user.id
      };
      const domain = getDomain();
      // eslint-disable-next-line no-undef
      document.cookie = `auth=${JSON.stringify(cookieObj)}; Domain=.${domain};`;
      return { ...state, authToken: action.data };
    }
    case 'LOGOUT': {
      delete api.defaults.headers.common.Authorization;
      // eslint-disable-next-line no-undef
      localStorage.removeItem(USER);
      // eslint-disable-next-line no-undef
      localStorage.removeItem(TOKEN);
      const domain = getDomain();
      // eslint-disable-next-line no-undef
      document.cookie = `auth=;max-age=0; Domain=.${domain};`; // Deleting cookie
      // eslint-disable-next-line no-undef
      document.cookie = `facilityId=;max-age=0; Domain=.${domain};`;
      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {}
      };
    }
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(TOKEN, action.data);
      return { ...state, authToken: action.data };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action.data };
    case 'SET_SELECTED_FACILITY_ID': {
      const domain = getDomain();
      // eslint-disable-next-line no-undef
      document.cookie = `facilityId=${action.data}; Domain=.${domain};`;
      return { ...state, selectedFacilityId: action.data };
    }
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    let getCookies = null;
    if (getCookie('auth')) {
      // eslint-disable-next-line no-undef
      getCookies = JSON.parse(getCookie('auth'));
      return getCookies?.accessToken;
    }
    return getCookies;
  };

  const getRefreshToken = () => {
    let getCookies = null;
    if (getCookie('auth')) {
      // eslint-disable-next-line no-undef
      getCookies = JSON.parse(getCookie('auth'));
      return getCookies?.refreshToken;
    }
    return getCookies;
  };

  const getCurrentUser = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON.parse(localStorage.getItem(USER))
      : {};
  };

  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return (loggedInUser && loggedInUser.roles && loggedInUser.roles[0]) || '';
  };

  const isAuthenticated = () => {
    return state.authenticated;
  };

  const initializeAuth = (authToken, authRefreshToken, userData) => {
    const refreshToken = authRefreshToken || getRefreshToken();
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    if (token) {
      api.defaults.headers.common.Authorization = `${token}`;
      dispatch({ type: 'SET_AUTH', data: { token, refreshToken, user } });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    initializeAuth,
    getCurrentUserRole,
    getCurrentUser
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
