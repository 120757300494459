import { gql } from '@apollo/client';

export const CREATE_TRUCK = gql`
  mutation createTruck($data: CreateTruckInput!) {
    createTruck(data: $data) {
      message
      data {
        id
        name
        isActive
        facility {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_TRUCK = gql`
  mutation updateTruck($where: CommonWhereInput!, $data: UpdateTruckInput!) {
    updateTruck(where: $where, data: $data) {
      message
      data {
        id
        name
        isActive
        facility {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_TRUCK = gql`
  mutation deleteTruck($where: CommonWhereInput!) {
    deleteTruck(where: $where) {
      message
    }
  }
`;
