import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Switch, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { map, trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { UPDATE_DRIVER } from '../graphql/Mutation';
import { GET_DRIVERS } from '../graphql/Queries';
import AddEditDriverModal from './AddEditDriverModal';
import DeleteModal from './DeleteModal';

const DriversList = () => {
  const {
    state: { selectedFacilityId }
  } = useContext(AppContext);
  const initialFilter = {
    skip: 0,
    limit: 10,
    sortBy: {
      field: 'createdAt',
      order: 'DESC'
    }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [showModal, setShowModal] = useState(false);
  const [driversFilter, setDriversFilter] = useState(initialFilter);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [driversLoading, setDriversLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [updateDriver] = useMutation(UPDATE_DRIVER, {
    onError() {}
  });

  const [fetchDrivers, { data, refetch }] = useLazyQuery(GET_DRIVERS, {
    fetchPolicy: 'network-only',
    variables: initialFilter,
    onCompleted(res) {
      setDriversLoading(false);
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.drivers?.total
      };
      setPaginationProp(pagination);
    },
    onError() {
      setDriversLoading(false);
    }
  });

  useEffect(() => {
    setDriversLoading(true);
    fetchDrivers({ variables: initialFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacilityId]);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setDriversLoading(true);
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });

    if (sorter?.column) {
      setDriversFilter({
        ...driversFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter?.columnKey,
          order: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchDrivers({
        variables: {
          ...driversFilter,
          skip,
          limit: pagination.pageSize,
          sortBy: {
            field: sorter?.columnKey,
            order: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          }
        }
      });
    } else {
      setDriversFilter({
        ...driversFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: 'createdAt',
          order: 'DESC'
        }
      });
      fetchDrivers({
        variables: {
          ...driversFilter,
          skip,
          limit: pagination.pageSize,
          sortBy: {
            field: 'createdAt',
            order: 'DESC'
          }
        }
      });
    }
  };

  const handleActiveChange = (status, id) => {
    return confirm({
      title: `${status === true ? 'Activate Driver' : 'Deactivate Driver'}`,
      centered: true,
      content: ` Are you sure you want to ${
        status === true ? 'Activate' : 'Deactivate'
      } Driver ?`,
      okText: `${status === true ? 'Yes, Activate' : 'Yes, Deactivate'}`,
      okButtonProps: { danger: status !== true },
      onOk: async () => {
        return new Promise((resolve) => {
          updateDriver({
            variables: {
              where: {
                id
              },
              data: {
                disabled: !status
              }
            }
          }).then(() => {
            refetch().then(() => {
              resolve();
            });
          });
        });
      }
    });
  };

  const columns = [
    {
      title: 'NAME',
      key: 'firstName',
      width: '25%',
      className: 'word-break',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (name) => {
        return (
          <>
            {name?.firstName} {name?.lastName}
          </>
        );
      }
    },
    {
      title: 'PHONE',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '15%',
      render: (phoneNumber) => {
        return phoneNumber?.[0] || '-';
      }
    },
    {
      title: 'FACILITIES',
      dataIndex: 'facilities',
      key: 'facilities',
      width: '25%',
      render: (facility) => {
        if (facility?.length > 0) {
          return map(facility, (item) => {
            return (
              <div key={item?.id}>
                {item?.name}
                <br />
              </div>
            );
          });
        }
        return '-';
      }
    },
    {
      title: 'ACTIVE',
      dataIndex: 'disabled',
      key: 'disabled',
      width: '10%',
      render: (disabled, record) => {
        return (
          <Switch
            checked={!disabled}
            key={record?.id}
            onChange={(status) => {
              handleActiveChange(status, record?.id);
            }}
          />
        );
      }
    },
    {
      title: 'ACTION',
      render: (record) => {
        return (
          <>
            <Tooltip title="Edit">
              <EditOutlined
                className="edit-button pointer"
                onClick={() => {
                  setShowModal(true);
                  setIsEdit(true);
                  setEditData(record);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                className="delete-button pointer"
                onClick={() => {
                  setDeleteModal(true);
                  setDeleteData(record);
                }}
              />
            </Tooltip>
          </>
        );
      }
    }
  ];

  const onSearchChange = async (value) => {
    setDriversLoading(true);
    setDriversFilter({
      ...driversFilter,
      skip: 0,
      where: {
        search: trim(value)
      }
    });
    fetchDrivers({
      variables: {
        ...driversFilter,
        skip: 0,
        where: {
          search: trim(value)
        }
      }
    });
    if (!value) {
      setDriversFilter({
        ...driversFilter,
        sortBy: {
          field: 'createdAt',
          order: 'DESC'
        },
        where: {
          search: ''
        }
      });
      fetchDrivers({
        variables: {
          ...driversFilter,
          sortBy: {
            field: 'createdAt',
            order: 'DESC'
          },
          where: {
            search: ''
          }
        }
      });
    }
  };

  return (
    <>
      {showModal && (
        <AddEditDriverModal
          showModal={showModal}
          setShowModal={setShowModal}
          refetch={refetch}
          isEdit={isEdit}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          refetch={refetch}
        />
      )}
      <Portal portalId="driver-add-btn">
        <div className="mb-10 mr-5">
          <SearchComponent
            id="search-container-id"
            name="Driver"
            getData={onSearchChange}
          />
        </div>
        <Button
          type="primary"
          onClick={() => {
            setShowModal(true);
            setIsEdit(false);
          }}
        >
          Add Driver
        </Button>
      </Portal>

      <CommonTable
        loadingData={driversLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        columns={columns}
        rowKey={(obj) => obj.id}
        data={data?.drivers?.drivers}
        tableClassName="common-table-css"
      />
    </>
  );
};

export default DriversList;
