import { MenuOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useContext } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import logo from '../../../assets/logo.png';
import { BREAKPOINTS } from '../../../common/constants';
import Facilities from './Facilities';
import UserProfile from './UserProfile';

const { Header } = Layout;

const AppHeader = ({ collapsed, setCollapsed }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.laptop}px)`);
  const isTabletViewport = useMedia(`(min-width: ${BREAKPOINTS.tablet}px)`);

  const {
    state: { selectedFacilityId }
  } = useContext(AppContext);
  const items = [
    {
      label: 'Dashboard',
      key: 'dashboard'
    },
    {
      label: 'Tags',
      key: 'tags'
    },
    {
      label: 'BW/MF Orders',
      key: 'bwmf',
      children: [
        {
          label: 'Current Orders',
          key: 'orders'
        },
        {
          label: 'Past Orders',
          key: 'orders/old'
        }
      ]
    },
    {
      label: 'WC Department',
      key: 'wc',
      children: [
        {
          label: 'Current Orders',
          key: 'orders/wc'
        },
        {
          label: 'Past Orders',
          key: 'orders/wc/old'
        }
      ]
    }
  ];

  const handleMenuClick = (e) => {
    // eslint-disable-next-line no-undef
    window.location.replace(
      `${process.env.REACT_APP_OLD_WEB_URL}/${e.key}/?facilityId=${selectedFacilityId}`
    );
  };

  return (
    <Header>
      <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg d-flex">
        {!isDesktopViewport && collapsed && (
          <div className="p-15">
            <MenuOutlined
              onClick={() => {
                setCollapsed(false);
              }}
              className="sidebar-svg"
            />
          </div>
        )}
        {isTabletViewport && (
          <div className="logo">
            <img className="logo-content" src={logo} alt="logo" />
          </div>
        )}
        {isDesktopViewport && (
          <Menu
            onClick={handleMenuClick}
            mode="horizontal"
            items={items}
            className="header-menu"
          />
        )}
      </div>
      <div className="header-notification">
        <Facilities />
        <UserProfile />
      </div>
    </Header>
  );
};
export default AppHeader;
