import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_DRIVERS = gql`
  query drivers(
    $skip: Int
    $limit: Int
    $where: DriverWhereInput
    $sortBy: DriverSortOnInput
  ) {
    drivers(skip: $skip, limit: $limit, where: $where, sortBy: $sortBy) {
      total
      drivers {
        id
        firstName
        lastName
        email
        phoneNumber
        roles
        disabled
        facilityIds
        facilities {
          id
          name
        }
      }
    }
  }
`;
