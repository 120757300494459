import { useMutation } from '@apollo/client';
import { Modal } from 'antd';
import React from 'react';
import { DELETE_DRIVER } from '../graphql/Mutation';

const DeleteModal = ({
  showModal,
  setShowModal,
  deleteData,
  refetch,
  setDeleteData
}) => {
  const [deleteDriver, { loading }] = useMutation(DELETE_DRIVER, {
    onError() {}
  });

  const handleCancel = () => {
    setShowModal(false);
    setDeleteData();
  };

  const handleOk = async () => {
    const response = await deleteDriver({
      variables: {
        where: {
          id: deleteData?.id
        }
      }
    });
    if (response) {
      setShowModal(false);
      refetch();
    }
  };

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      okText="Yes, Delete"
      cancelText="No, Cancel"
      centered
      title="Delete Driver"
      okButtonProps={{ danger: true }}
      onOk={handleOk}
      confirmLoading={loading}
    >
      <h3>Are you sure you want to delete driver {deleteData?.name} ? </h3>
    </Modal>
  );
};

export default DeleteModal;
