import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_TRUCKS = gql`
  query trucks(
    $skip: Int
    $limit: Int
    $where: TruckWhereInput!
    $sortBy: TruckSortOnInput
  ) {
    trucks(skip: $skip, limit: $limit, where: $where, sortBy: $sortBy) {
      total
      trucks {
        id
        name
        isActive
        facility {
          id
          name
          company
        }
      }
    }
  }
`;
