import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_TRAILERS = gql`
  query trailers(
    $skip: Int
    $limit: Int
    $where: TrailerWhereInput!
    $sortBy: TrailerSortOnInput
  ) {
    trailers(skip: $skip, limit: $limit, where: $where, sortBy: $sortBy) {
      total
      trailers {
        id
        name
        isActive
        facility {
          id
          name
        }
      }
    }
  }
`;
