import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Switch, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { UPDATE_TRAILER } from '../graphql/Mutation';
import { GET_TRAILERS } from '../graphql/Queries';
import AddEditTrailerModal from './AddEditTrailerModal';
import DeleteModal from './DeleteModal';

const TrailersList = () => {
  const {
    state: { selectedFacilityId }
  } = useContext(AppContext);
  const initialFilter = {
    skip: 0,
    limit: 10,
    sortBy: {
      field: 'createdAt',
      order: 'DESC'
    },
    where: {
      facilityId: selectedFacilityId
    }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [showModal, setShowModal] = useState(false);
  const [trailerFilter, setTrailerFilter] = useState(initialFilter);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [trailersLoading, setTrailersLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [updateTrailer] = useMutation(UPDATE_TRAILER, {
    onError() {}
  });

  const [fetchTrailers, { data, refetch }] = useLazyQuery(GET_TRAILERS, {
    fetchPolicy: 'network-only',
    variables: initialFilter,
    onCompleted(res) {
      setTrailersLoading(false);
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.trailers?.total
      };
      setPaginationProp(pagination);
    },
    onError() {
      setTrailersLoading(false);
    }
  });

  useEffect(() => {
    setTrailersLoading(true);
    fetchTrailers({ variables: initialFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacilityId]);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setTrailersLoading(true);
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setTrailerFilter({
        ...trailerFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter?.columnKey,
          order: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchTrailers({
        variables: {
          ...trailerFilter,
          skip,
          limit: pagination.pageSize,
          sortBy: {
            field: sorter?.columnKey,
            order: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          }
        }
      });
    } else {
      setTrailerFilter({
        ...trailerFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: 'createdAt',
          order: 'DESC'
        }
      });
      fetchTrailers({
        variables: {
          ...trailerFilter,
          skip,
          limit: pagination.pageSize,
          sortBy: {
            field: 'createdAt',
            order: 'DESC'
          }
        }
      });
    }
  };

  const handleActiveChange = (status, id) => {
    return confirm({
      title: `${status === true ? 'Activate Trailer' : 'Deactivate Trailer'}`,
      centered: true,
      content: ` Are you sure you want to ${
        status === true ? 'Activate' : 'Deactivate'
      } Trailer ?`,
      okText: `${status === true ? 'Yes, Activate' : 'Yes, Deactivate'}`,
      okButtonProps: { danger: status !== true },
      onOk: async () => {
        return new Promise((resolve) => {
          updateTrailer({
            variables: {
              where: {
                id
              },
              data: {
                isActive: status
              }
            }
          }).then(() => {
            refetch().then(() => {
              resolve();
            });
          });
        });
      }
    });
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      className: 'word-break',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },
    {
      title: 'FACILITY',
      dataIndex: 'facility',
      key: 'facility',
      width: '25%',
      render: (facility) => {
        return facility?.name;
      }
    },
    {
      title: 'ACTIVE',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '10%',
      render: (isActive, record) => {
        return (
          <Switch
            checked={isActive}
            key={record?.id}
            onChange={(status) => {
              handleActiveChange(status, record?.id);
            }}
          />
        );
      }
    },
    {
      title: 'ACTION',
      render: (record) => {
        return (
          <>
            <Tooltip title="Edit">
              <EditOutlined
                className="edit-button pointer"
                onClick={() => {
                  setShowModal(true);
                  setIsEdit(true);
                  setEditData(record);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                className="delete-button pointer"
                onClick={() => {
                  setDeleteModal(true);
                  setDeleteData(record);
                }}
              />
            </Tooltip>
          </>
        );
      }
    }
  ];

  const onSearchChange = async (value) => {
    setTrailersLoading(true);
    setTrailerFilter({
      ...trailerFilter,
      skip: 0,
      where: {
        facilityId: selectedFacilityId,
        name: trim(value)
      }
    });
    fetchTrailers({
      variables: {
        ...trailerFilter,
        skip: 0,
        where: {
          facilityId: selectedFacilityId,
          name: trim(value)
        }
      }
    });
    if (!value) {
      setTrailerFilter({
        ...trailerFilter,
        skip: 0,
        where: {
          facilityId: selectedFacilityId
        }
      });
      fetchTrailers({
        variables: {
          ...trailerFilter,
          where: {
            facilityId: selectedFacilityId
          }
        }
      });
    }
  };

  return (
    <>
      {showModal && (
        <AddEditTrailerModal
          showModal={showModal}
          setShowModal={setShowModal}
          refetch={refetch}
          isEdit={isEdit}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          refetch={refetch}
        />
      )}
      <Portal portalId="trailer-add-btn">
        <div className="mb-10 mr-5">
          <SearchComponent
            id="search-container-id"
            name="Trailer"
            getData={onSearchChange}
          />
        </div>
        <Button
          type="primary"
          onClick={() => {
            setShowModal(true);
            setIsEdit(false);
          }}
        >
          Add Trailer
        </Button>
      </Portal>

      <CommonTable
        loadingData={trailersLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        columns={columns}
        rowKey={(obj) => obj.id}
        data={data?.trailers?.trailers}
        tableClassName="common-table-css"
      />
    </>
  );
};

export default TrailersList;
