import { useLazyQuery, useMutation } from '@apollo/client';
import { Form, Input, Modal, Select } from 'antd';
import { map, replace, startsWith, trimStart, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { GET_FACILITIES } from '../../../app/graphql/Queries';
import { REGEX, SKIP_RECORD } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import { CREATE_DRIVER, UPDATE_DRIVER } from '../graphql/Mutation';

const { required } = formValidatorRules;
const AddEditDriverModal = ({
  showModal,
  setShowModal,
  isEdit,
  editData,
  setEditData,
  refetch
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isFacilityEnd, setIsFacilityEnd] = useState(false);
  const [facilityData, setFacilityData] = useState([]);
  const [fetchFacilities, { loading }] = useLazyQuery(GET_FACILITIES, {
    fetchPolicy: 'network-only',
    variables: {
      skip: 0,
      limit: 10
    },
    onError() {},
    onCompleted(res) {
      if (res?.facilities?.facilities?.length < SKIP_RECORD) {
        setIsFacilityEnd(true);
      }

      setFacilityData(
        uniqBy([...facilityData, ...res?.facilities?.facilities], 'id')
      );
    }
  });

  useEffect(() => {
    fetchFacilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [createDriver, { loading: createLoading }] = useMutation(
    CREATE_DRIVER,
    { onError() {} }
  );

  const [updateDriver, { loading: updateLoading }] = useMutation(
    UPDATE_DRIVER,
    { onError() {} }
  );

  const handleCancel = () => {
    setShowModal(false);
    setEditData();
  };

  const handleScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
    if (scrolledToBottom && !isFacilityEnd) {
      fetchFacilities({
        variables: {
          skip: facilityData?.length,
          limit: 10
        }
      });
    }
  };

  const handleFinish = (formValues) => {
    if (!isEdit) {
      createDriver({
        variables: {
          data: {
            ...formValues,
            phoneNumber: startsWith(formValues?.phoneNumber, '+1')
              ? formValues?.phoneNumber
              : `+1${formValues?.phoneNumber}`,
            firstName: trimStart(formValues?.firstName),
            lastName: trimStart(formValues?.lastName)
          }
        }
      })
        .then((res) => {
          if (res?.data?.createDriver) {
            setEditData();
            refetch();
            form.resetFields();
            setShowModal(false);
          }
        })
        .catch(() => {});
    } else {
      updateDriver({
        variables: {
          data: {
            ...formValues,
            phoneNumber: startsWith(formValues?.phoneNumber, '+1')
              ? formValues?.phoneNumber
              : `+1${formValues?.phoneNumber}`,
            firstName: trimStart(formValues?.firstName),
            lastName: trimStart(formValues?.lastName)
          },
          where: {
            id: editData?.id
          }
        }
      })
        .then((res) => {
          if (res?.data?.updateDriver) {
            setEditData();
            refetch();
            form.resetFields();
            setShowModal(false);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <Modal
      visible={showModal}
      onCancel={handleCancel}
      onOk={() => {
        form.submit();
      }}
      title={`${isEdit ? 'Edit Driver' : 'Add Driver'}`}
      okText={`${isEdit ? 'Save' : 'Add'}`}
      confirmLoading={createLoading || updateLoading}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          ...editData,
          phoneNumber: replace(editData?.phoneNumber?.[0], /\D/g, '').slice(
            -10
          ),
          facilityIds:
            editData?.facilityIds === null ? [] : editData?.facilityIds
        }}
        onFinish={handleFinish}
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[required, { whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[required, { whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[
            required,
            () => ({
              validator(rule, value) {
                if (value) {
                  // eslint-disable-next-line no-param-reassign
                  value = value?.split(' ')?.join('');
                  const numberPattern = REGEX?.PHONE_NUMBER;
                  if (!numberPattern?.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject(
                      'should not be greater than 10 digits'
                    );
                  }
                }
                return Promise?.resolve();
              }
            })
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Facility" name="facilityIds" rules={[required]}>
          <Select
            loading={loading}
            mode="multiple"
            onPopupScroll={handleScroll}
          >
            {map(facilityData, (item) => {
              return (
                <Option key={item?.id} value={item?.id}>
                  {item?.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditDriverModal;
