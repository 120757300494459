import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Switch, Tooltip } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import CommonTable from '../../../components/CommonTable';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { UPDATE_TRUCK } from '../graphql/Mutation';
import { GET_TRUCKS } from '../graphql/Queries';
import AddEditTruckModal from './AddEditTruckModal';
import DeleteModal from './DeleteModal';

const TrucksList = () => {
  const {
    state: { selectedFacilityId }
  } = useContext(AppContext);
  const initialFilter = {
    skip: 0,
    limit: 10,
    sortBy: {
      field: 'createdAt',
      order: 'DESC'
    },
    where: {
      facilityId: selectedFacilityId
    }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1
  };
  const [showModal, setShowModal] = useState(false);
  const [trucksFilter, setTrucksFilter] = useState(initialFilter);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [trucksLoading, setTrucksLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [sortedInfo, setSortedInfo] = useState({});
  const [updateTruck] = useMutation(UPDATE_TRUCK, {
    onError() {}
  });

  const [fetchTrucks, { data, refetch }] = useLazyQuery(GET_TRUCKS, {
    fetchPolicy: 'network-only',
    variables: initialFilter,
    onCompleted(res) {
      setTrucksLoading(false);
      const pagination = {
        ...paginationProp,
        defaultPageSize: 10,
        total: res?.trucks?.total
      };
      setPaginationProp(pagination);
    },
    onError() {
      setTrucksLoading(false);
    }
  });

  useEffect(() => {
    setTrucksLoading(true);
    setTrucksFilter({
      ...trucksFilter,
      where: {
        facilityId: selectedFacilityId
      }
    });
    fetchTrucks({ variables: initialFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacilityId]);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setTrucksLoading(true);
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setTrucksFilter({
        ...trucksFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter?.columnKey,
          order: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchTrucks({
        variables: {
          ...trucksFilter,
          skip,
          limit: pagination.pageSize,
          sortBy: {
            field: sorter?.columnKey,
            order: sorter?.order === 'ascend' ? 'ASC' : 'DESC'
          }
        }
      });
    } else {
      setTrucksFilter({
        ...trucksFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: 'createdAt',
          order: 'DESC'
        }
      });
      fetchTrucks({
        variables: {
          ...trucksFilter,
          skip,
          limit: pagination.pageSize,
          sortBy: {
            field: 'createdAt',
            order: 'DESC'
          }
        }
      });
    }
  };

  const handleActiveChange = (status, id) => {
    return confirm({
      title: `${status === true ? 'Activate Truck' : 'Deactivate Truck'}`,
      centered: true,
      content: ` Are you sure you want to ${
        status === true ? 'Activate' : 'Deactivate'
      } Truck ?`,
      okText: `${status === true ? 'Yes, Activate' : 'Yes, Deactivate'}`,
      okButtonProps: { danger: status !== true },
      onOk: async () => {
        return new Promise((resolve) => {
          updateTruck({
            variables: {
              where: {
                id
              },
              data: {
                isActive: status
              }
            }
          }).then(() => {
            refetch().then(() => {
              resolve();
            });
          });
        });
      }
    });
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      className: 'word-break',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: '25%'
    },
    {
      title: 'FACILITY',
      dataIndex: 'facility',
      key: 'facility',
      width: '25%',
      render: (facility) => {
        return facility?.name;
      }
    },
    {
      title: 'ACTIVE',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '10%',
      render: (isActive, record) => {
        return (
          <Switch
            checked={isActive}
            key={record?.id}
            onChange={(status) => {
              handleActiveChange(status, record?.id);
            }}
          />
        );
      }
    },
    {
      title: 'ACTION',
      render: (record) => {
        return (
          <>
            <Tooltip title="Edit">
              <EditOutlined
                className="edit-button pointer"
                onClick={() => {
                  setShowModal(true);
                  setIsEdit(true);
                  setEditData(record);
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                className="delete-button pointer"
                onClick={() => {
                  setDeleteModal(true);
                  setDeleteData(record);
                }}
              />
            </Tooltip>
          </>
        );
      }
    }
  ];

  const onSearchChange = async (value) => {
    setTrucksLoading(true);
    setTrucksFilter({
      ...trucksFilter,
      skip: 0,
      where: {
        facilityId: selectedFacilityId,
        name: trim(value)
      }
    });
    fetchTrucks({
      variables: {
        ...trucksFilter,
        skip: 0,
        where: {
          facilityId: selectedFacilityId,
          name: trim(value)
        }
      }
    });
    if (!value) {
      setTrucksFilter({
        ...trucksFilter,
        skip: 0,
        where: {
          facilityId: selectedFacilityId
        }
      });
      fetchTrucks({
        variables: {
          ...trucksFilter,
          where: {
            facilityId: selectedFacilityId
          }
        }
      });
    }
  };

  return (
    <>
      {showModal && (
        <AddEditTruckModal
          showModal={showModal}
          setShowModal={setShowModal}
          refetch={refetch}
          isEdit={isEdit}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      {deleteModal && (
        <DeleteModal
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          deleteData={deleteData}
          setDeleteData={setDeleteData}
          refetch={refetch}
        />
      )}
      <Portal portalId="truck-add-btn">
        <div className="mb-10 mr-5">
          <SearchComponent
            id="search-container-id"
            name="Trucks"
            getData={onSearchChange}
          />
        </div>
        <Button
          type="primary"
          onClick={() => {
            setShowModal(true);
            setIsEdit(false);
          }}
        >
          Add Trucks
        </Button>
      </Portal>

      <CommonTable
        loadingData={trucksLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        columns={columns}
        rowKey={(obj) => obj.id}
        data={data?.trucks?.trucks}
        tableClassName="common-table-css"
      />
    </>
  );
};

export default TrucksList;
