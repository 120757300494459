import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';
import { debounce, map, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { SKIP_RECORD } from '../../../common/constants';
import { GET_FACILITIES } from '../../graphql/Queries';

const { Option } = Select;
let facilitiesDebounceJob;
const Facilities = () => {
  const initialFilter = {
    skip: 0,
    limit: 10
  };
  const {
    dispatch,
    state: { selectedFacilityId }
  } = useContext(AppContext);
  const { search } = useLocation();
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [facilitiesLoading, setFacilitiesLoading] = useState(false);
  const [facilitiesEnd, setFacilitiesEnd] = useState(false);
  const [facilitiesDebounceCall, setFacilitiesDebounceCall] = useState(0);
  const [fetchFacilities] = useLazyQuery(GET_FACILITIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (facilitiesData?.length === 0) {
        if (search.substring(search.indexOf('=') + 1)) {
          dispatch({
            type: 'SET_SELECTED_FACILITY_ID',
            data: Number(search.substring(search.indexOf('=') + 1))
          });
        } else {
          dispatch({
            type: 'SET_SELECTED_FACILITY_ID',
            data: res?.facilities?.facilities?.[0]?.id
          });
        }
      }
      if (res?.facilities?.facilities?.length < SKIP_RECORD) {
        setFacilitiesEnd(true);
      }
      setFacilitiesData(
        uniqBy([...facilitiesData, ...res?.facilities?.facilities], 'id')
      );
      setFacilitiesLoading(false);
    },
    onError() {
      setFacilitiesLoading(false);
    }
  });

  useEffect(() => {
    fetchFacilities({ variables: initialFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFacilitiesScroll = (event) => {
    if (facilitiesDebounceJob) {
      facilitiesDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    facilitiesDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !facilitiesEnd) {
        setFacilitiesLoading(true);
        setFacilitiesDebounceCall((prevState) => prevState + 1);
        fetchFacilities({
          variables: {
            ...initialFilter,
            skip: (facilitiesDebounceCall + 1) * SKIP_RECORD
          }
        });
      }
    }, 500);
    facilitiesDebounceJob();
  };

  return (
    <div>
      {selectedFacilityId && (
        <Select
          className="facilities-dropdown"
          defaultValue={selectedFacilityId}
          onPopupScroll={onFacilitiesScroll}
          onSelect={(id) => {
            dispatch({
              type: 'SET_SELECTED_FACILITY_ID',
              data: id
            });
          }}
          loading={facilitiesLoading}
        >
          {map(facilitiesData, (item) => {
            return (
              <Option key={item?.id} value={item?.id} label={item?.name}>
                {item?.name}
              </Option>
            );
          })}
        </Select>
      )}
    </div>
  );
};

export default Facilities;
